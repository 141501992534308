import 'react-native-get-random-values';
import React, {useEffect, useState} from 'react';
import {StyleSheet, useWindowDimensions, View} from 'react-native';
import {ThemeManager} from './util/ThemeManager.ts';
import {ChessBoard} from './components/ChessBoard.tsx';
import {ChessGame} from './util/chess/Chess.ts';
import {Text} from 'react-native-paper';
import {BUILD_VERSION, CORE_URL} from './config/NetworkingConfig.ts';

export default function App() {
  const {width, height} = useWindowDimensions();

  const [serverId, setServerId] = useState<number | null>(null);

  const getWidth = (): number => {
    if (Math.min(width, height) > ThemeManager.WIDTH) {
      return ThemeManager.WIDTH;
    }
    return Math.max(Math.min(width, height), ThemeManager.MIN_WIDTH);
  };

  const padding: number = Math.min(width, height) > ThemeManager.WIDTH ? 16 : 8;

  useEffect(() => {
    fetch(`${CORE_URL}/system`, {method: 'GET'})
      .then(response => {
        return response.text();
      })
      .then(result => {
        const data: {serverId: number} = JSON.parse(result);
        setServerId(data.serverId);
      })
      .catch(err => {
        console.error('could not fetch data from get:', err);
      });
  }, []);

  return (
    <View>
      <View style={[styles.container, {height}, StyleSheet.absoluteFill]}>
        <View
          style={{
            width: getWidth(),
            height: getWidth(),
            backgroundColor: 'gray',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <ChessBoard
            size={getWidth() - padding}
            data={ChessGame.getInstance().getChessBoard()}
          />
        </View>
        <View style={{flexDirection: 'row'}}>
          <Text>Server Id: {serverId}</Text>
          <Text>Build Version: {BUILD_VERSION}</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  br: {
    height: 12,
  },
  btn: {
    backgroundColor: '#222',
    padding: 10,
  },
  btnText: {
    color: '#fff',
  },
});
